export const popupData = {
  polyèdre: {
    content:
      "Un polyèdre est un objet ou un solide possèdant au minimum 4 faces.",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/a/a4/Dodecahedron.svg",
  },
  vertex: {
    content:
      "Vertex, vertices ou sommet en français est une extrémité formée par la rencontre de deux arêtes ou plus. Un cube possède 8 vertices.",
  },
  edge: {
    content:
      "Les edges ou arêtes sont les lignes/connexions entre deux vertices. Un cube en possède 12.",
  },
  face: {
    content:
      "Une face est un polygone. Elle se crée à partir de 3 edges et 3 vertices (le triangle).",
  },
  viewport: {
    content:
      "Le viewport est le panneau de visualisation dans lequel vous pouvez voir votre modèle 3D et votre scène.",
    image: require("../img/viewport.png"),
  },
  timeline: {
    content:
      "La timeline est le panneau qui vous indique à quelle frame vous êtes dans votre animation. C'est aussi ici que vous pouvez ajouter, modifier ou supprimer des keyframes.",
    image: require("../img/timeline.png"),
  },
  outliner: {
    content:
      "L'outliner est le panneau qui vous permet de voir tous les objets de votre scène. Vous pouvez les sélectionner, les masquer ou les déplacer.",
    image: require("../img/outliner.png"),
  },
  properties: {
    content:
      "Le panneau properties vous permet de modifier les propriétés de vos objets, matériaux, lumières, etc. Mais aussi de modifier les paramètres de rendu et d'export.",
    image: require("../img/properties.png"),
  },
  lowpoly: {
    content:
      "Le low poly est un style de modélisation 3D qui consiste à créer des objets avec un nombre de polygones minimal.",
    image:
      "https://mir-s3-cdn-cf.behance.net/project_modules/1400/a1002f89934319.5e125aa627c32.png",
  },
  cube: {
    content:
      "Le cube est un polyèdre régulier. Il possède 6 faces, 12 arêtes et 8 sommets.",
    image: require("../img/cube.png"),
  },
  sphère: {
    content:
      "La sphère est un objet géométrique en 3D. En théorie, elle n'a pas de faces, mais en 3D, elle est composée de nombreuses faces, plus vous en rajouter, plus votre sphere sera ronde.",
    image: require("../img/sphere.png"),
  },
  cylindre: {
    content:
      "Le cylindre est un objet géométrique en 3D. Il possède 2 faces circulaires et une surface latérale.",
    image: require("../img/cylinder.png"),
  },
  cone: {
    content:
      "Le cone est un objet géométrique en 3D. Il possède une face circulaire et une surface latérale.",
    image: require("../img/cone.png"),
  },
  plane: {
    content:
      "Le plane est un objet géométrique en 2D. Il possède 1 faces, 4 arêtes et 4 sommets. Techniquement, il n'a pas de volume et n'est pas faisable dans la vraie vie. Pensez à une feuille de papier celle-ci est fine mais reste quelques micromètres.",
    // image: require("../img/plane.png"),
  },
  translate: {
    content:
      "Translate est une transformation qui déplace un objet dans l'espace 3D.",
    image: require("../img/translate.webp"),
  },
  rotate: {
    content:
      "Rotate est une transformation qui fait tourner un objet autour de son centre.",
    image: require("../img/rotate.webp"),
  },
  scale: {
    content:
      "Scale est une transformation qui agrandit ou réduit la taille d'un objet.",
    image: require("../img/scale.webp"),
  },
  loopcut: {
    image: require("../img/b-loopcut.gif"),
  },
  bevel: {
    image: require("../img/b-bevel.gif"),
  },
  subdivision: {
    image: require("../img/b-subdivide.gif"),
  },
  subdivide: {
    image: require("../img/b-subdivide.gif"),
  },
  extrude: {
    image: require("../img/b-extrude.gif"),
  },
  boolean: {
    image: require("../img/b-boolean.gif"),
  },
  array: {
    image: require("../img/b-array.gif"),
  },
};
