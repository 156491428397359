import { popupData } from "./popupData";

document.addEventListener("DOMContentLoaded", function () {
  const listItems = document.querySelectorAll(".table-topics li");

  listItems.forEach((item, index) => {
    item.addEventListener("mouseover", () => {
      if (index > 0) {
        listItems[index - 1].classList.add("previous-1");
      }
      if (index > 1) {
        listItems[index - 2].classList.add("previous-2");
      }
    });

    item.addEventListener("mouseleave", () => {
      if (index > 0) {
        listItems[index - 1].classList.remove("previous-1");
      }
      if (index > 1) {
        listItems[index - 2].classList.remove("previous-2");
      }
    });
  });

  const emElements = document.querySelectorAll("em");

  console.log(popupData || "No content found");

  emElements.forEach((item) => {
    const keyword = item.textContent.trim().toLowerCase().replace(/\s+/g, "");
    const popupDataEntry = popupData[keyword];

    if (!popupDataEntry) return;

    item.addEventListener("mouseenter", (event) => {
      const popup = document.createElement("div");
      popup.classList.add("popup");

      if (popupDataEntry.image) {
        const img = document.createElement("img");
        img.src = popupDataEntry.image;
        popup.appendChild(img);

        console.log("Img data fetched" + img.src);
      }

      if (popupDataEntry.content) {
        const p = document.createElement("p");
        p.textContent = popupDataEntry.content;
        popup.appendChild(p);

        console.log("Content data fetched");
      }

      item.appendChild(popup);
      const rect = item.getBoundingClientRect();
      popup.style.top = `${window.scrollY + rect.bottom + 5}px`;
      popup.style.left = `${window.scrollX + rect.left}px`;

      popup.offsetHeight;

      popup.classList.add("show");

      item.classList.add("emphasized");

      item.addEventListener(
        "mouseleave",
        () => {
          popup.classList.remove("show");
          item.classList.remove("emphasized");

          setTimeout(() => {
            popup.remove();
          }, 300);
        },
        { once: true }
      );
    });
  });
});
